import React, {
    useEffect,
    useState
} from 'react';
import {ReactSortable} from 'react-sortablejs';

import Stock from '../../types/Stock';
import StockViewItem from './StockViewItem';

export function StockViewBoard(props: { stocks: Stock[], onRemove, onReorder }) {

    const [list, setList] = useState([]);

    useEffect(() => {
        setList(props.stocks.map((s) => ({...s, id: s.ticker_symbol})));
    }, [props.stocks]);

    function handleSort() {
        const sorted = list.map((l) => l.ticker_symbol);
        if (props.onReorder) {
            props.onReorder(sorted);
        }
    }

    return (
        <ReactSortable list={list}
                       handle=".cursor-move"
                       setList={setList}
                       onSort={handleSort}
                       className="py-4 md:px-8 lg:px-12 flex-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {list.map((s) => {
                const isWideView = list.length === 1;
                const wideViewClass = isWideView ? 'md:col-span-2 lg:col-span-3' : '';

                return (
                    <div className={wideViewClass} key={s.ticker_symbol}>
                        <StockViewItem stock={s}
                                       wideView={isWideView}
                                       onCloseView={props.onRemove}/>
                    </div>
                );
            })}
        </ReactSortable>
    );
}
