import React, {
    useEffect,
    useRef,
    useState
} from 'react';
import Tooltip from 'antd/lib/tooltip';
import {Link} from 'gatsby';
import {
    format,
    subYears
} from 'date-fns';
import {isEqual} from 'lodash';

import {getStockRangeData, getStockData} from '../../api';
import toPercent from '../../utils/toPercent';
import approximate from '../../utils/approximate';
import {
    generateRoute,
    ROUTES,
    lightweightChartColors
} from '../../common';
import Stock from '../../types/Stock';
import PriceDaily from '../../types/PriceDaily';


const DATE_FORMAT = 'yyyy-MM-dd';

interface StatData {
    latestPrice: PriceDaily;
    pricePercentChange?: number;
}

const CHART_HEIGHT = 150;
const STOCK_VIEW_REFRESH_MINUTES = 15;
const STOCK_VIEW_REFRESH_MILLISECONDS = (STOCK_VIEW_REFRESH_MINUTES * 60) * 1000;

function StockView({stock, containerWidth}: { stock: Stock, containerWidth: number }) {
    const chartContainer = useRef(null);
    const chart = useRef();
    const chartSeries = useRef();

    const [priceHistory, setPriceHistory] = useState<PriceDaily[]>([]);
    const [statData, setStatData] = useState<StatData>(null);

    useEffect(() => {
        if (!stock)
            return;

        async function _() {
            const today = format(new Date(), DATE_FORMAT);
            const yearAgo = format(subYears(new Date(), 1), DATE_FORMAT);

            const response = await getStockRangeData(stock.ticker_symbol, yearAgo, today);

            setPriceHistory(response.data.history);
        }

        _();
    }, [stock]);

    useEffect(() => {
        if (!chartContainer.current) return;

        if (chart.current || chartSeries.current) return;

        // @ts-ignore
        chart.current = LightweightCharts.createChart(chartContainer.current, {
            width: chartContainer.current.clientWidth,
            height: CHART_HEIGHT,
            // priceScale: {
            //     borderVisible: false
            // },
            // timeScale: {
            //     fixLeftEdge: true
            // },
            rightPriceScale: {
                borderColor: "rgba(42, 46, 57, 0)"
            },
            timeScale: {
                borderColor: "rgba(203, 208, 215, 1)"
            },
            layout: {
                backgroundColor: "#ffffff",
                textColor: "#646a77"
            },
            // grid: {
            //     vertLines: {
            //         color: "rgba(42, 46, 57, 0)"
            //     },
            //     horzLines: {
            //         color: "rgba(42, 46, 57, 0.6)"
            //     }
            // }
        });

        // @ts-ignore
        chartSeries.current = chart.current.addAreaSeries({
            topColor: 'rgba(33, 150, 243, 0.56)',
            bottomColor: 'rgba(33, 150, 243, 0.04)',
            lineColor: 'rgba(33, 150, 243, 1)',
            lineWidth: 2,
        });
    }, [stock, chartContainer]);

    useEffect(() => {
        if (!chart.current)
            return;

        // @ts-ignore
        chart.current.resize(containerWidth, CHART_HEIGHT);
    }, [containerWidth]);

    // Set priceHistory as graph data
    useEffect(() => {
        if (!chart.current || !chartSeries.current)
            return;

        // @ts-ignore
        chartSeries.current.setData(priceHistory.map((p) => ({time: p.trading_date, value: p.close})));
    }, [priceHistory]);

    // Set latest price
    useEffect(() => {
        if (priceHistory.length == 0) {
            setStatData(null);
            return;
        }

        const latestPrice = priceHistory[priceHistory.length - 1];
        const previousPrice = priceHistory[priceHistory.length - 2];
        if (!previousPrice) {
            setStatData({latestPrice: latestPrice});
            return;
        }

        const price_delta = latestPrice.close - previousPrice.close
        const percentChange = 100 * (price_delta / previousPrice.close)

        const statData = {
            latestPrice: latestPrice,
            pricePercentChange: percentChange
        };

        setStatData(statData);
    }, [priceHistory]);

    // Calculate how should we color the graph
    useEffect(() => {
        if (!statData)
            return;

        const pricePercentChange = statData.pricePercentChange;
        if (isNaN(pricePercentChange)) {
            // @ts-ignore
            chartSeries.current.applyOptions(lightweightChartColors.default);
            return;
        }

        const isDown = pricePercentChange < 0;
        const isUp = pricePercentChange > 0;

        if (isDown) {
            // @ts-ignore
            chartSeries.current.applyOptions(lightweightChartColors.down);
        } else if (isUp) {
            // @ts-ignore
            chartSeries.current.applyOptions(lightweightChartColors.up);
        } else {
            // @ts-ignore
            chartSeries.current.applyOptions(lightweightChartColors.default);
        }
    }, [statData]);

    useEffect(() => {
        const interval = setInterval(async () => {
            if (!priceHistory.length)
                return;

            const {length} = priceHistory;
            const lastItem = priceHistory[length - 1];
            if (!lastItem)
                return;

            const response = await getStockData(stock.ticker_symbol, lastItem.trading_date);
            const responsePriceHistory = response.data.history;

            if (isEqual(responsePriceHistory, lastItem)) {
                return;
            }

            const allTheOthers = priceHistory.slice(0, length - 1);
            const newPriceHistory = [...allTheOthers, responsePriceHistory];

            setPriceHistory(newPriceHistory);
        }, STOCK_VIEW_REFRESH_MILLISECONDS);
        return () => clearInterval(interval);
    }, [priceHistory]);

    if (!stock)
        return <p>Select a stock...</p>

    let statDataContainer = null;
    if (statData) {
        statDataContainer = (
            <div className="px-6 py-4">
                <dl className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 lg:space-x-1">
                    <div className="lg:border-r-2">
                        <div className="grid grid-cols-2 sm:gap-4">
                            <dt className="text-sm leading-5 font-medium text-gray-500">Open</dt>
                            <dd className="text-sm leading-5 text-gray-900 mt-0">{statData.latestPrice.open}</dd>
                        </div>
                        <div className="grid grid-cols-2 sm:gap-4">
                            <dt className="text-sm leading-5 font-medium text-gray-500">High</dt>
                            <dd className="text-sm leading-5 text-gray-900 mt-0">{statData.latestPrice.high}</dd>
                        </div>
                    </div>
                    <div className="lg:border-r-2">
                        <div className="grid grid-cols-2 sm:gap-4">
                            <dt className="text-sm leading-5 text-gray-500">Low</dt>
                            <dd className="text-sm leading-5 text-gray-900 mt-0">{statData.latestPrice.low}</dd>
                        </div>
                        <div className="grid grid-cols-2 sm:gap-4">
                            <dt className="text-sm leading-5 text-gray-500">Close</dt>
                            <dd className="text-sm leading-5 text-gray-900 mt-0">{statData.latestPrice.close}</dd>
                        </div>
                    </div>
                    <div>
                        <div className="grid grid-cols-2 sm:gap-4">
                            <dt className="text-sm leading-5 text-gray-500">Volume</dt>
                            <dd className="text-sm leading-5 text-gray-900 mt-0">{approximate(statData.latestPrice.volume)}</dd>
                        </div>
                        <div className="grid grid-cols-2 sm:gap-4">
                            <dt className="text-sm leading-5 font-medium text-gray-500">Value</dt>
                            <dd className="text-sm leading-5 text-gray-900 mt-0">{approximate(statData.latestPrice.volume * statData.latestPrice.close)}</dd>
                        </div>
                    </div>
                </dl>
            </div>
        );
    }

    function PercentChange({statData}) {
        const hasValue = (statData && !isNaN(statData.pricePercentChange));
        const value = hasValue ?
            toPercent(statData.pricePercentChange) : 'NA';

        const cls = [];
        if (hasValue) {
            const isDown = statData.pricePercentChange < 0;
            const isUp = statData.pricePercentChange > 0;
            if (isDown)
                cls.push('text-red-600');
            else if (isUp)
                cls.push('text-green-600');
        }

        return (
            <div className="flex flex-col px-2 md:px-5 border-l">
                <span className={cls.join(' ')}>{value}</span>
                <span className="text-xs text-gray-400">% Change</span>
            </div>
        );
    }

    function LastPrice({statData}) {
        const value = statData ? statData.latestPrice.close : 'NA';

        return (
            <div className="flex flex-col pl-5 pr-2 md:pr-5">
                <span className={statData ? 'font-black' : ''}>{value}</span>
                <span className="text-xs text-gray-400">Last</span>
            </div>
        );
    }

    return (
        <>
            <header className="flex items-center pl-6 pr-4 pt-4">
                <div className="flex flex-col flex-1">
                    <h3 className="text-2xl font-blank text-gray-900">{stock.ticker_symbol}</h3>
                    <div className="flex-1">{stock.company_name}</div>
                </div>
                <LastPrice statData={statData}/>
                <PercentChange statData={statData}/>
            </header>
            <div className="pl-6">
                <Link className="flex items-center mt-2 cursor-pointer"
                      to={generateRoute(ROUTES.CHART, {stock_code: stock.ticker_symbol})}>
                    <svg className="w-4 h-4" fill="currentColor"
                         viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z"/>
                    </svg>
                    <span className="ml-2">View chart</span>
                </Link>
            </div>
            <div className="mt-8">
                <div ref={chartContainer}/>
            </div>
            {statDataContainer}
        </>
    );
}

function StockViewItem({stock, onCloseView, wideView = false}: { stock: Stock, onCloseView, wideView: boolean }) {
    const stockViewRef = useRef();
    const [width, setWidth] = useState(100);

    useEffect(() => {
        if (!stockViewRef.current)
            return;

        // @ts-ignore
        setWidth(stockViewRef.current.clientWidth);
    }, [stockViewRef.current]);

    useEffect(() => {
        if (!stockViewRef.current)
            return;

        // @ts-ignore Typings for ResizeObserver is not yet shipped with Typescript
        // https://github.com/Microsoft/TypeScript/issues/28502
        const resizeObserver = new ResizeObserver(() => {
            // @ts-ignore
            setWidth(stockViewRef.current.clientWidth);
        });

        resizeObserver.observe(stockViewRef.current);

        return function () {
            resizeObserver.disconnect();
        }
    }, []);

    return (
        <div className="flex flex-col" ref={stockViewRef}>
            <div className="mb-1 flex justify-end">
                {!wideView && (
                    <div className="hidden md:block mt-2 cursor-move">
                        <Tooltip title="Drag to move" mouseEnterDelay={0.8}>
                            <svg className="w-6 h-6 cursor-pointer text-gray-300 hover:text-gray-400" fill="none"
                                 stroke="currentColor" viewBox="0 0 24 24"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11"/>
                            </svg>
                        </Tooltip>
                    </div>
                )}
                <div onClick={() => onCloseView(stock)} className="mt-2">
                    <svg className="w-6 h-6 cursor-pointer text-gray-300 hover:text-gray-400" fill="none"
                         stroke="currentColor" viewBox="0 0 24 24"
                         xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                </div>
            </div>
            <div className="bg-white rounded shadow">
                <StockView stock={stock} containerWidth={width}/>
            </div>
        </div>
    );
}

export default StockViewItem;
