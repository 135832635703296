import React, {
    useEffect,
    useRef,
    useState
} from 'react';
import message from 'antd/lib/message';
import Empty from 'antd/lib/empty';
import Checkbox from 'antd/lib/checkbox';
import notification from 'antd/lib/notification';
import Tooltip from 'antd/lib/tooltip';
import Fuse from 'fuse.js';
import Downshift from 'downshift';
import {CSSTransitionGroup} from 'react-transition-group';
import * as Sentry from '@sentry/browser';
import {navigate} from '@reach/router';

import Layout from '../../components/layout';
import SEO from '../../components/SEO';
import {getStocks} from '../../api';
import {getStringSorter} from '../../common';
import Stock from '../../types/Stock';
import '../../styles/stocks.css';
import {StockViewBoard} from '../../components/stock-lookup/StockViewBoard';


function SearchBar({stocks, onSelectStock}: { stocks: Stock[], onSelectStock: Function }) {

    function filterStockBySearchTerm(searchTerm) {
        if (!searchTerm.length) {
            return stocks;
        }

        const fuse = new Fuse(stocks, {
            keys: ['ticker_symbol', 'company_name']
        })

        const searchResult = fuse.search<Stock>(searchTerm);

        return searchResult.map((result) => result.item);
    }

    function handleChange(selection, {clearSelection}) {
        if (!selection)
            return;

        onSelectStock(selection)
        clearSelection();
    }

    return (
        <Downshift
            onChange={handleChange}
            itemToString={(item) => item ? item.ticker_symbol : ''}>
            {({
                  getInputProps,
                  getItemProps,
                  getMenuProps,
                  isOpen,
                  inputValue,
                  highlightedIndex
              }) => {
                const filteredStocks = filterStockBySearchTerm(inputValue);

                return (
                    <div className="relative">
                        <input type="text"
                               autoFocus={true}
                               placeholder="Search stocks..."
                               className="transition-colors duration-100 ease-in-out text-gray-600 py-3 pr-4 pl-10 block w-full appearance-none leading-normal border border-transparent rounded-lg focus:outline-none font-semibold text-left truncate bg-white focus:border-gray-300"
                               {...getInputProps()}
                        />

                        <div className="pointer-events-none absolute inset-y-0 left-0 pl-4 flex items-center">
                            <svg className="fill-current pointer-events-none text-gray-600 w-4 h-4"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path
                                    d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/>
                            </svg>
                        </div>

                        <CSSTransitionGroup
                            transitionName="search-result"
                            transitionEnterTimeout={500}
                            transitionLeaveTimeout={300}>
                            {isOpen &&
                            <div className="z-10 origin-top absolute top-0 inset-x-0 mt-12 rounded-md shadow-lg">
                                <div className="rounded-md bg-white ring-1 ring-black ring-opacity-5">
                                    <div className="h-64 overflow-y-auto">
                                        <div className="py-1 max-h-full" role="menu"
                                             aria-orientation="vertical"
                                             aria-labelledby="options-menu"
                                             {...getMenuProps()}>
                                            {
                                                filteredStocks
                                                    .map((item, index) => {
                                                        const highlightedClass = highlightedIndex === index ? 'bg-gray-100' : '';

                                                        return (
                                                            <div
                                                                className={`block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 ${highlightedClass}`}
                                                                {...getItemProps({
                                                                    key: item.ticker_symbol,
                                                                    index,
                                                                    item
                                                                })}>
                                                                <div onClick={() => onSelectStock(item)}>
                                                                    <div className="flex-1">
                                                                        <div
                                                                            className="font-bold">{item.ticker_symbol}</div>
                                                                        <div
                                                                            className="text-gray-500">{item.company_name}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </CSSTransitionGroup>
                    </div>
                )
            }}
        </Downshift>
    );
}

class SelectedStocksStore {
    storageKey = 'selected-stock-lookup';
    stocks: Stock[] = [];

    constructor() {
        if (typeof window !== 'undefined') {
            const storedData = localStorage.getItem(this.storageKey);
            if (storedData) {
                try {
                    this.stocks = JSON.parse(storedData);
                } catch (e) {
                    Sentry.captureMessage('An error occurred while trying to hydrate selected-stock-lookup from localStorage', {
                        extra: {
                            stored_data: storedData,
                        },
                        contexts: {
                            error: e
                        }
                    });
                }
            }
        }
    }

    add(stock: Stock) {
        const exist = this.stocks.find((s) => s.ticker_symbol === stock.ticker_symbol);
        if (exist)
            return false;

        this.stocks.push(stock);

        this.persist();

        return true;
    }

    remove(stock: Stock) {
        this.stocks = this.stocks.filter((s) => s.ticker_symbol !== stock.ticker_symbol);

        this.persist();
    }

    persist() {
        try {
            localStorage.setItem(this.storageKey, JSON.stringify(this.stocks));
        } catch (e) {
            Sentry.captureMessage(`An error occurred while trying to persist ${this.storageKey} in localStorage`, {
                extra: {
                    stocks_array: this.stocks,
                },
                contexts: {
                    error: e
                }
            });
        }
    }

    replaceWith(stocks: Stock[]) {
        this.stocks = stocks;

        this.persist();

        return true;
    }

    get() {
        return [...this.stocks];
    }
}

const SIDE_BY_SIDE_LIMIT = 12;

function StockLookupPage({location}) {
    const [stocks, setStocks] = useState<Stock[]>([]);
    const [isPreloaded, setIsPreloaded] = useState(false);
    const store = useRef<SelectedStocksStore>(new SelectedStocksStore());
    const [selectedStocks, setSelectedStocks] = useState<Stock[]>([]);
    const [sideBySideView, setSideBySideView] = useState(false);

    useEffect(function fetchStocksList() {
        async function _() {
            const response = await getStocks();
            if (response.status != 200) {
                message.error('Could not fetch stock list. Please try again later.');
                return;
            }

            const sorted = response.data.stocks.sort(getStringSorter('ticker_symbol'));

            setStocks(sorted);
        }

        _();
    }, []);

    useEffect(function preloadSelectedStocks() {
        if (isPreloaded)
            return;

        if (stocks.length === 0)
            return;

        const urlSearchParams = new URLSearchParams(location.search);
        const preloadFromUrl = urlSearchParams.has('view');

        if (preloadFromUrl) {
            const viewParam = urlSearchParams.get('view');
            const preloadStocksTickers = viewParam.toUpperCase().split(',');

            const stocksMap = [];
            for (let s of stocks) {
                stocksMap[s.ticker_symbol] = s;
            }

            const preloadStocks = [];
            for (let p of preloadStocksTickers) {
                if (stocksMap[p]) {
                    preloadStocks.push(stocksMap[p]);
                }
            }

            setSelectedStocks(preloadStocks);
            store.current.replaceWith(preloadStocks);
        } else {
            setSelectedStocks(store.current.get());
        }

        setIsPreloaded(true);
    }, [stocks]);

    useEffect(function updateUrl() {
        updateUrlFromList(selectedStocks);
    }, [selectedStocks]);

    function updateUrlFromList(stocks) {
        const viewTickers = [];
        for (let s of stocks) {
            viewTickers.push(s.ticker_symbol);
        }

        if (viewTickers.length) {
            navigate(`?view=${viewTickers.join(',')}`);
        }
    }

    function handleSelectStock(stock: Stock) {
        if (sideBySideView) {
            if (selectedStocks.length === SIDE_BY_SIDE_LIMIT) {
                notification.open({
                    message: 'Side-by-side limit',
                    description: `You already reach the side-by-side limit of ${SIDE_BY_SIDE_LIMIT}.`,
                    duration: 6
                });

                return;
            }

            if (store.current.add(stock)) {
                setSelectedStocks(store.current.get());
            }
        } else if (store.current.replaceWith([stock])) {
            setSelectedStocks(store.current.get());
        }
    }

    function handleCloseStockView(stock: Stock) {
        store.current.remove(stock);
        setSelectedStocks(store.current.get());
    }

    function handleReorder(stockList) {
        const currentStockList = store.current.get();
        const currentStockMap = {};
        for (let s of currentStockList) {
            currentStockMap[s.ticker_symbol] = s;
        }

        const newList = [];
        for (let sl of stockList) {
            newList.push(currentStockMap[sl]);
        }

        store.current.replaceWith(newList);
        updateUrlFromList(newList);
    }

    const mainDisplay = selectedStocks.length > 0 ? (
        <StockViewBoard stocks={selectedStocks}
                        onRemove={handleCloseStockView}
                        onReorder={handleReorder}/>
    ) : (
        <div className="mt-12 md:mx-8 lg:mx-12 flex justify-center border-2 border-gray-300 rounded-md">
            <Empty description="View price details. Start by searching a stock from the search box above..."
                   image={Empty.PRESENTED_IMAGE_SIMPLE}/>
        </div>
    );

    return (
        <Layout>
            {/*<SEO title="Stocks" description="Quickly see stock price actions"/>*/}

            <div className="h-screen bg-gray-100">
                <div className="flex flex-col bg-gray-100">
                    <div className="mt-6 mb-2 text-center">
                        <h1 className="text-2xl font-semibold text-gray-900">Stock Lookup</h1>
                        <p className="text-lg text-gray-700 px-4">Search by ticker symbol, or company name to view price
                            details</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="px-3 py-4 flex place-content-center">
                            <div className="w-full md:w-1/2">
                                <SearchBar stocks={stocks} onSelectStock={handleSelectStock}/>
                                <Tooltip title={`View price details side-by-side. Up to ${SIDE_BY_SIDE_LIMIT} stocks.`}
                                         mouseEnterDelay={1.5}>
                                    <Checkbox className="mt-1 ml-2"
                                              onChange={(e) => setSideBySideView(e.target.checked)}
                                              checked={sideBySideView}>View side-by-side</Checkbox>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    {mainDisplay}
                </div>
            </div>
        </Layout>
    )
}


export default StockLookupPage;
